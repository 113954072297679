import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/erro',
        name: 'erro',
        component: () => import('./telas/Erro.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: true
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('./telas/Home.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
    },
    {
        path: '/produto/:idProduto',
        name: 'produto',
        component: () => import('./telas/Home.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
    },
    {
        path: '/carrinho',
        name: 'carrinho',
        component: () => import('./telas/Carrinho.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
        
    },
    {
        path: '/listapedidos',
        name: 'listapedidos',
        component: () => import('./telas/ListaPedidos.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
        
    },
    {
        path: '/busca/:text',
        name: 'busca',
        component: () => import('./telas/Busca.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
        
    }

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
