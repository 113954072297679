import axios from 'axios';
import { getAuth, getIdToken } from "firebase/auth";

export default class {

    constructor(api) {
        this.urlApi = api;
        this.fpid = null;
    }

    setFpid(fpid) {
        if(fpid)
            this.fpid = fpid;
    }

    async getBearerToken() {

        const currentUser = getAuth().currentUser;

        if(currentUser === null) {

            while(this.fpid == null) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }

            return "anonymous " + this.fpid;
        } else {
            return await getIdToken(currentUser, false);
        }

    }

    async headers() {
      //console.log(this.getBearerToken())
        return { 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + await this.getBearerToken(),
        };
    }

    async get(endpoint/*, data = null*/) {

        if(typeof endpoint != "string")
            return null;

        //TRATAR PARAMS typeof data != object || data == null

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

}