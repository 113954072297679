<template>
  
  <div class="grid" v-if="logado">
      <div class="col-12 text-center">
        Bem vindo, {{ nome }}!
      </div>
      <div class="col-12 text-center">
        <Button
          label="desconectar"
          icon="pi pi-power-off"
          @click="logout"
        />
      </div>
  </div>

  <div class="grid" v-if="!logado && acao == ''">
    <!-- <div class="col-6">
      <Button
        label="logar com facebook"
        icon="pi pi-facebook"
        class="w-full"
        @click="logarFacebook"
      />
    </div>-->
    <div class="col-12" v-show="autenticacao">
      <Button
        label="logar com e-mail"
        icon="pi pi-at"
        class="w-full p-button-secondary"
        @click="acao = 'logar'"
      />
    </div>
    <div class="col-12">
      <Button
        label="cadastre-se"
        icon="pi pi-plus"
        class="w-full p-button-primary"
        @click="acao = 'cadastrar'"
      />
    </div>
  </div>
  
  <div class="grid" v-if="acao == 'logar'">

    <div class="col-6">
        <label  class="block mb-2" for="email">E-mail</label>
        <InputText
            id="email"
            type="email"
            v-model="email"
            class="w-full"
            :class="(fieldsError.some(v => v === 'email'))?'p-invalid':''"
        />
    </div>
    <div class="col-4">
        <label  class="block mb-2" for="senha">Senha</label>
        <Password
            id="senha"
            v-model="senha"
            inputClass="w-full"
            :feedback="false"
            :toggleMask="true"
            :class="(fieldsError.some(v => v === 'senha'))?'p-invalid':''"
        />
    </div>
    <div class="col-2">
        <Button
            icon="pi pi-check-circle"
            class="w-full p-button-success mt-4"
            v-tooltip.top="'Logar'"
            @click="logarEmail"
        />
    </div>
  </div>

  <div class="grid" v-if="acao == 'cadastrar'">
    <div class="col-12">
        <label  class="block mb-2" for="nome">Nome completo / Razão social</label>
        <InputText
            id="nome"
            type="text"
            v-model="nome"
            class="w-full"
            :class="(fieldsError.some(v => v === 'nome'))?'p-invalid':''"
        />
    </div>
    <div class="col-6">
        <label  class="block mb-2" for="cpfcnpj">CPF / CNPJ<br><small style="font-weight: 600">(obrigatório p/ pagamento online)</small></label>
        <InputText
            id="cpfcnpj"
            type="text"
            v-model="cpfcnpj"
            class="w-full"
            :class="(fieldsError.some(v => v === 'cpfcnpj'))?'p-invalid':''"
        />
    </div>
    <div class="col-6">
        <label  class="block mb-2" for="cep">CEP</label><br>
        <InputMask
            id="cep"
            type="text"
            v-model="cep"
            class="w-full"
            mask="99.999-999"
            :class="(fieldsError.some(v => v === 'cep'))?'p-invalid':''"
            @blur="validaCep"
        />
    </div>
    <div class="col-12">
        <label  class="block mb-2" for="endereco">Endereço</label>
        <InputText
            id="endereco"
            type="text"
            v-model="endereco"
            class="w-full"
            :class="(fieldsError.some(v => v === 'endereco'))?'p-invalid':''"
        />
    </div>
    <div class="col-8">
        <label  class="block mb-2" for="complemento">Complemento</label>
        <InputText
            id="complemento"
            type="text"
            v-model="complemento"
            class="w-full"
            :class="(fieldsError.some(v => v === 'complemento'))?'p-invalid':''"
        />
    </div>
    <div class="col-4">
        <label  class="block mb-2" for="numero">Número</label>
        <InputText
            id="numero"
            type="text"
            v-model="numero"
            class="w-full"
            :class="(fieldsError.some(v => v === 'numero'))?'p-invalid':''"
        />
    </div>
    <div class="col-6">
        <label  class="block mb-2" for="bairro">Bairro</label>
        <InputText
            id="bairro"
            type="text"
            v-model="bairro"
            class="w-full"
            :class="(fieldsError.some(v => v === 'bairro'))?'p-invalid':''"
        />
    </div>
    <div class="col-6">
        <label  class="block mb-2" for="cidade">Cidade</label>
        <InputText
            id="cidade"
            type="text"
            v-model="cidade"
            class="w-full"
            :class="(fieldsError.some(v => v === 'cidade'))?'p-invalid':''"
        />
    </div>
    <div class="col-6">
        <label  class="block mb-2" for="estado">Estado</label>
        <InputText
            id="estado"
            type="text"
            v-model="estado"
            class="w-full"
            :class="(fieldsError.some(v => v === 'estado'))?'p-invalid':''"
        />
    </div>
    <div class="col-6">
        <label  class="block mb-2" for="telefone">Telefone</label>
        <InputMask
            id="telefone"
            type="text"
            v-model="telefone"
            class="w-full"
            mask="(99) 9?9999-9999"
            :class="(fieldsError.some(v => v === 'telefone'))?'p-invalid':''"
        />
    </div>
    <div class="col-6" v-show="tipo_login == ''">
        <Button
            label="logar com facebook"
            icon="pi pi-facebook"
            class="w-full"
            @click="cadastrarFacebook"
        />
    </div>
    <div class="col-6" v-show="tipo_login == ''">
        <Button
            label="logar com e-mail"
            icon="pi pi-at"
            class="w-full p-button-secondary"
            @click="tipo_login = 'email'"
        />
    </div>
    <div class="col-6" v-show="tipo_login == 'email' && autenticacao">
        <label  class="block mb-2" for="email">E-mail</label>
        <InputText
            id="email"
            type="email"
            v-model="email"
            class="w-full"
            :class="(fieldsError.some(v => v === 'email'))?'p-invalid':''"
        />
    </div>
    <div class="col-4" v-show="tipo_login == 'email' && autenticacao">
        <label  class="block mb-2" for="senha">Senha</label>
        <Password
            id="senha"
            v-model="senha"
            inputClass="w-full"
            :toggleMask="true"
            :feedback="false"
            :class="(fieldsError.some(v => v === 'senha'))?'p-invalid':''"
        />
    </div>
    <div class="col-2" v-show="tipo_login == 'email' && autenticacao">
        <Button
            icon="pi pi-check-circle"
            class="w-full p-button-success mt-4"
            v-tooltip.top="'Finalizar'"
            @click="cadastrarEmail"
        />
    </div>

    <div class="col-12" v-show="tipo_login == 'email' && !autenticacao">
        <Button
            icon="pi pi-check-circle"
            class="w-full p-button-success"
            v-tooltip.top="'Finalizar'"
            @click="cadastrarEmail"
        />
    </div>

  </div>

  <div class="loading" v-show="carregando">
    <div class="center-spinner" v-show="carregando">
      <ProgressSpinner />
    </div>
  </div>

</template>

<script>

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
  emits: [
    'logado',
    'deslogado'
  ],
  data() {

    return {

      // Controle de Apresentação
      autenticacao: true, // CONTROLADA POR PERMISSÃO
      logado: false,
      fieldsError: [],
      tipo_login: "",
      acao: "",
      carregando: false,

      // Formulário
      nome: "",
      cpfcnpj: "",
      cep: "",
      endereco: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      telefone: "",
      email: "",
      senha: "",
      latitude: 0,
      longitude: 0,
    }
  },
  watch: {

    '$root.empresa': {
      handler: function(empresa) {
        if(empresa != null) {
          this.autenticacao = empresa.config.autenticacao.habilitada;
        } else {
          this.autenticacao = true;
        }

        if(!this.autenticacao) {
          this.tipo_login = 'email';
          this.acao = 'cadastrar';
        }

      },
      deep: true,
      immediate: true
    },

    '$root.user': {
      handler: function(user) {
        if(user) {
          this.nome = user.nome;
          this.logado = true;
          this.acao = '';
        } else {
          this.nome = "";
          this.logado = false;

          if(!this.autenticacao)
            this.acao = 'cadastrar';
          else
            this.acao = '';

        }

        this.tipo_login = 'email';

      },
      deep: true,
      immediate: true
    },

    '$root.gps': {
      handler: function(gps) {
        if(gps != null) {
          this.latitude = gps.latitude;
          this.longitude = gps.longitude;
        }
      },
      deep: true,
      immediate: true
    },

  },
  methods: {

    async validaCep() {

      this.carregando = true;

      const idx = this.fieldsError.indexOf("cep");
      if(idx >= 0) this.fieldsError.splice(idx, 1);

      try {
        
        let response = await axios.get("https://brasilapi.com.br/api/cep/v1/" + this.cep);
        response = response.data;
      
        if(!this.estado) this.estado = response.state;
        if(!this.cidade) this.cidade = response.city;
        if(!this.bairro) this.bairro = response.neighborhood;
        if(!this.endereco) this.endereco = response.street;
        
        this.carregando = false;

      } catch (error) {
        
        this.carregando = false;

        if (error.response.data.name == "CepPromiseError")
          this.fieldsError.push("cep");

      }

    },

    async logarFacebook() {



    },

    async logarEmail() {

      this.carregando = true;

      try {
      
        const auth = getAuth();

        await signInWithEmailAndPassword(auth, this.email, this.senha);

        const response = await this.$api.post("/carrinho/atualizar-cliente", {
            "idAnonimo": this.$fpid,
            "idEmpresa": this.$root.empresa.id
        });

        if(!response.data.success) {
            console.log("Erro ao atualizar o dono do carrinho!");
            console.log(response.data);
        }

        this.carregando = false;

        this.$emit("logado");

      } catch (error) {

        this.carregando = false;

        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: error.message + " (" + error.code + ")",
          life: 5000,
        });

      }

    },
    async cadastrarEmail() {

      this.carregando = true;

      if(!this.autenticacao) {
        this.email = uuidv4() + "@tmpdelivery.com";
        this.senha = "Tmp!Delivery!1";
      }

      console.log(this.email);

      let dados = {
        "nome": this.nome,
        "cpf": this.cpfcnpj,
        "telefone": this.telefone,
        "cep": this.cep,
        "endereco": this.endereco,
        "numero": this.numero,
        "complemento": this.complemento,
        "bairro": this.bairro,
        "cidade": this.cidade,
        "estado": this.estado,
        "email": this.email,
        "senha": this.senha,
        "idEmpresa": this.$root.empresa.id,
        "idAnonimo": this.$fpid,
        "latitude": this.latitude,
        "longitude": this.longitude,
      };

      const response = await this.$api.post("/usuarios", dados);

      dados = response.data;

      if(dados.success) {

        this.logarEmail();

      } else if(typeof dados.fields != "undefined") {

        this.fieldsError = dados.fields;

      }

      this.carregando = false;

      this.$toast.add({
        severity: (response.data.success)?"success":"error",
        summary: (response.data.success)?"Sucesso!":"ERRO!",
        detail: response.data.message,
        life: (response.data.success)?3000:5000,
      });

    },
    async cadastrarFacebook() {
      this.tipo_login = 'facebook';
    },
    async logout() {

      await signOut(getAuth());

      this.$emit("deslogado");

    }
  }

}
</script>

<style>

</style>