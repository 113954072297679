<template>
  <!--<div class="status-component overflow-hidden relative">
    <Image v-if="imageIcon" :src="imageIcon" alt="Logo" class="status-profile-icon absolute overflow-hidden border-circle border-2 border-white z-1" imageClass="max-w-full h-auto block" />
    <Image :src="imageStatus" :preview="true" alt="Status" class="block" imageClass="max-w-full h-auto block" />
 </div>-->
  <div class="circle">
    <img v-if="imageIcon" :src="imageIcon" alt="imageIcon" />
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="enable-background:new -580 439 577.9 194;"
     xml:space="preserve">  
    <circle cx="50" cy="50" r="40" />
  </svg>
  </div>
  <div class="text-center px-1 w-full">
      <span class="w-full " v-if="nome">{{nome}}</span>
      <span class="w-full " v-else>Categoria</span>
    </div>
</template>

<script>
export default {
  name: "Status",
  props: {
    value: Object,
  },
  data: function () {
    return {
      imageStatus: this.value.imagem,
      imageIcon: this.value.icone,
      nome:this.value.nome
    };
  },
};
</script>

<style lang="scss" scoped>
.status-profile-icon {
  top: 0.5rem;
  left: 0.5rem;
  width: 50px;
  max-width: 50%;
}

.status-component {
  border-radius: 0.6rem;
}
.circle{
  position:relative;
  width:100px;
  height:100px;
  svg{
    fill:none;
    stroke: var(--primaryBackgroundColor, $primaryBackgroundColor);
    stroke-linecap: round;
    stroke-width:3;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation: stroke-draw 6s ease-out infinite alternate; 
    // animation: stroke-draw 6s cubic-bezier(0.77, 0, 0.175, 1) infinite alternate; 
  }
  img{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    width:70px;
    border-radius:50%;
  }
}

.img-bord-branco{
  border-width: 3px;
border-color: var(--primaryBackgroundColor, $primaryBackgroundColor);
border-style: solid;
}
</style>