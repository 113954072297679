<template>
  <div class="produto-resumido-component overflow-hidden">
    <div class="produto-resumido-imagem" :style="'--quadrado: ' + w + 'px'" v-if="imagens">
      <Image
        :src="imagens[0]"
        alt="Produto"
        class="block"
        imageClass="max-w-full h-auto block"
        @click="addItem"
      />
    </div>
    <div class="produto-resumido-titulo text-center text-lg mt-2 px-1 w-full">
      <span class="w-full">{{ nome }}</span>
    </div>
    <div class="produto-resumido-preco text-center font-bold text-lg  w-full">
      <span class="w-full">
        <span >
          {{ valorVarejo }}
        </span><br>
        
      </span>
    </div>
    <div class="produto-resumido-acoes text-center text-lg mb-2 ">
      <Button
        label="Adicionar"
        icon="pi pi-shopping-cart"
        class="botao-comprar p-button-text md:mr-3"
        @click="addItem"
      />
      <Button icon="pi pi-heart" class="botao-favoritar p-button-text" @click="likeItem" />
    </div>
  </div>

  <Dialog :position="($utils.isMobileCheck())?'bottom':'center'" v-model:visible="displayAdicionarCarrinho" :style="{width: '400px', 'max-width': '100%'}" :modal="true" :closable="true">
    <template #header>
      <h5 class="m-0 text-xl font-medium"><i class="pi pi-exclamation-circle" style="font-size: 1.3rem"></i> Detalhes do Produto</h5>
    </template>
    <AdicionarCarrinho :value="value" @add="displayAdicionarCarrinho=false,this.displayPergunta = true"/>
  </Dialog>
  <Dialog v-model:visible="displayPergunta">
    <template #header>
		<h3>Finalizar</h3>
	</template>
    <div>Deseja finalizar a compra?</div>
	

	<template #footer>
		<Button label="Continuar comprando" icon="pi pi-times" class="p-button-text" @click="displayPergunta = false"/>
        <Button label="Finalizar" icon="pi pi-check" autofocus @click="$router.push('/carrinho')"/>
	</template>
</Dialog>

</template>

<script>

export default {
  name: "ProdutoResumido",
  props: {
    value: Object,
  },
  emits: [
    'add',
    'like'
  ],
  data() { 
    return {
      displayAdicionarCarrinho: false,
      w: 0,
      displayPergunta:false
    };
  },
  mounted() {
    this.w = this.$el.parentElement.offsetWidth;
  },
  computed: {
    nome() {
      if(this.value && typeof this.value.nome != "undefined" && this.value.nome) {
        return this.value.nome;
      }
      return "";
    },
    imagens() {
      if(this.value && typeof this.value.imagens != "undefined" && this.value.imagens) {
        return this.value.imagens;
      }
      return [];
    },
    valorAtacado() {
      if(this.value && typeof this.value.valorAtacado == "number") {
        return this.$utils.formatCurrency(this.value.valorAtacado);
      }
      return "";
    },
    valorVarejo() {
      if(this.value && typeof this.value.valorVarejo == "number") {
        return this.$utils.formatCurrency(this.value.valorVarejo);
      }
      return "";
    },
  },
  methods: {

    addItem() {
      this.displayAdicionarCarrinho = true;
      
      this.$emit("add", this.value);
    },
    likeItem() {
      this.$emit("like", this.value);
    },

  },
};
</script>

<style lang="scss">

  .produto-resumido-component {
    background-color: $alternativeBackgroundColor;
    border-radius: 0.3rem;
  }

  .produto-resumido-titulo, .produto-resumido-preco {
    height: 2.8rem;
    display: table;
  }

  .produto-resumido-titulo span, .produto-resumido-preco span.w-full {
    display: table-cell;
    vertical-align: middle;
  }

</style>

<style lang="scss" scoped>

  .produto-resumido-imagem {
      cursor: pointer;
  }

  .p-button.p-button-text {
    color: $alternativeColor;
    font-size: 1rem;
  }

  .p-button.p-button-text:enabled:hover {
    color: $primaryColor;
    background: none;
  }

  .p-button {
    @media screen and (max-width: 500px) {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }

  sup {
    font-size: 0.7rem;
  }

</style>